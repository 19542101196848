$(document).on('turbolinks:load', function(){
  if ($('#user_search').length > 0 && $('#filter_dropdowns_url').val().length > 0) {
    $(document).on("change", '#q_organisation_id_eq', function(){
      let organisation_id = $(this).val();
      if(organisation_id.length > 0) {
        $.ajax({
          url: `/filter_users_dropdowns`,
          method: "GET",
          dataType: "json",
          data: {organisation_id: organisation_id},
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            //// These lines resets the child values to avoid incongruous behavior
      	    if ($('#q_role_user_relationships_team_id_eq').val().length > 0 || $('#q_role_user_relationships_user_role_name_eq').val().length > 0) {
      	      $('#q_role_user_relationships_user_role_name_eq')[0].selectize.setValue(null, true);
      	      $('#q_role_user_relationships_team_id_eq')[0].selectize.setValue(null, true);
      	    };
            getAndFilterTeams(response);
          }
        });
      }
    });

    $(document).on("change", '#q_role_user_relationships_team_id_eq', function(){
      let team_id = $(this).val();
      if(team_id.length > 0) {
        $.ajax({
          url: `/filter_users_dropdowns`,
          method: "GET",
          dataType: "json",
          data: {team_id: team_id},
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            setOrganisation(response);
            getAndFilterTeams(response);
          }
        });
      }
    });
  }
});

function setOrganisation(response) {
  let organisation = response["organisation"];
  let organisation_dropdown = $("#q_organisation_id_eq")[0].selectize
  organisation_dropdown.setValue(organisation[0], true);
}

function getAndFilterTeams(response) {
  let teams = response["teams"];
  let teams_dropdown = $("#q_role_user_relationships_team_id_eq")[0].selectize
  teams_dropdown.clearOptions();
  $(teams).each(function() {
    teams_dropdown.addOption({value: this[0], text: this[1]});
  });
}
