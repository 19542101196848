import flatpickr from "flatpickr";

$(document).on('turbolinks:load', function() {
  // for whatever reason, sometimes flatpickr was taking longer to load.
  // so this has been refactored to check that its not null before trying to use it.
  let datePicker = document.getElementsByClassName("date-picker");
  let datePickerFuture = document.getElementsByClassName("date-picker-future");
  let dateTimePickerFuture = document.getElementsByClassName("date-time-picker-future");
  let datePickerTodayFuture = document.getElementsByClassName("date-picker-today-future");
  let datePickerPast = document.getElementsByClassName("date-picker-past");
  let dateRangePicker = document.getElementsByClassName("date-range-picker");
  if (datePicker != null && flatpickr != null) {
    datePicker.flatpickr({
      altInput: true,
      altFormat: "d/m/Y",
    });
  };
  if (datePickerFuture != null && flatpickr != null) {
    datePickerFuture.flatpickr({
      dateFormat: "d/m/Y",
      minDate: new Date().fp_incr(1)
    });
  };
  if (dateTimePickerFuture != null && flatpickr != null) {
    dateTimePickerFuture.flatpickr({
      enableTime: true,
      dateFormat: "d/m/Y H:i",
      minDate: new Date().fp_incr(1)
    });
  };
  if (datePickerTodayFuture != null && flatpickr != null) {
    datePickerTodayFuture.flatpickr({
      dateFormat: "d/m/Y",
      minDate: new Date()
    });
  };
  if (datePickerPast != null && flatpickr != null) {
    datePickerPast.flatpickr({
      dateFormat: "d/m/Y",
      maxDate: new Date()
    });
  };
  if (dateRangePicker != null && flatpickr != null) {
    dateRangePicker.flatpickr({
      mode: "range",
      dateFormat: "d/m/Y"
    });
  };
});
