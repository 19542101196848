$(document).on("turbolinks:load", function () {
  if (
    determineControllerPath(window.location.href) == "" &&
    $("#new_user").length > 0
  ) {
    //reload the page in 30 minutes if user is logged out and on a form
    window.setTimeout(() => {
      window.location.reload();
    }, "1800000");
  }
});

window.determineControllerPath = function (url) {
  let controller = "";
  // If condition based on regex search of url str
  if (/organisation_admin/.test(url)) {
    // Get org_id with regex match for first number following '/' one or more times
    controller = `organisation_admin/organisations${url.match(/\/\d+/)}`;
  } else if (/organisation_manager/.test(url)) {
    // Get org_id with regex match for first number following '/' one or more times
    controller = `organisation_manager/organisations${url.match(/\/\d+/)}`;
  } else if (/organisation_user/.test(url)) {
    // Get org_id with regex match for first number following '/' one or more times
    controller = `organisation_user/organisations${url.match(/\/\d+/)}`;
  } else if (/super_admin/.test(url)) {
    controller = "super_admin";
  }
  return controller;
};

// this scrolls horizontally to a specific point on the table
window.scrollToId = function (letter) {
  // if the letter clicked has active-sort-link, dont do anything as we dont need to scroll to a specific point
  // also check that there is a team with the letter clicked
  if (
    !letter.classList.contains("active-sort-link") &&
    $("#" + letter.getAttribute("value")).length > 0
  ) {
    // a letter has been clicked, if letter had been clicked before this, remove the class
    $(".active-sort-link").removeClass("active-sort-link");
    // add class to letter just clicked
    letter.classList.add("active-sort-link");
    // scroll the table all the way to the left, if this step is skipped, the scroll gets extremely confused as to where it should scroll
    // i think this is down to the first two columns being fixed.
    $(".fixed-columns-table").animate(
      {
        scrollLeft: 0,
      },
      0,
    );
    // find id in table and scroll to position, - 400 as thats the width of the first two fixed columns
    $(".fixed-columns-table").animate(
      {
        scrollLeft: $("#" + letter.getAttribute("value")).position().left - 400,
      },
      0,
    );
  }
};

window.showChild = function (checkbox, id) {
  if (checkbox.checked) {
    $("#" + id).removeClass("is-hidden");
  } else {
    $("#" + id).addClass("is-hidden");
    if ($("#organisation_" + id).length > 0) {
      $("#organisation_" + id).prop("checked", false);
      $("#organisation_" + id).val("");
    }
  }
};

window.oaSamlCheck = function (checkbox) {
  if (checkbox.id == "organisation_open_athens") {
    //clear saml
    $("#organisation_saml").prop("checked", false);
    $("#saml_fields").addClass("is-hidden");
    $("#organisation_saml_configuration_attributes_metadata_xml_url").val("");
    $("#organisation_saml_configuration_attributes_acs_url").val("");
    $("#organisation_saml_configuration_attributes_sign_on_url").val("");
    $("#organisation_saml_configuration_attributes_idp_sso_target_url").val("");
    $("#organisation_saml_configuration_attributes_idp_entity_id").val("");
    $("#organisation_saml_configuration_attributes_idp_certificate").val("");
    $("#organisation_saml_configuration_attributes_idp_metadata_url").val("");
    $("#organisation_saml_configuration_attributes_idp_logout_url").val("");
  } else if (checkbox.id == "organisation_saml") {
    //clear oa
    $("#organisation_open_athens").prop("checked", false);
    $("#open_athens_domain").addClass("is-hidden");
    $("#organisation_open_athens_domain").val("");
    $("#open_athens_entity").addClass("is-hidden");
    $("#organisation_open_athens_entity").val("");
  }
};

window.submitClosestForm = function (input) {
  $(input).closest("form").submit();
};

window.alertUser = function (message) {
  event.preventDefault();
  alert(message);
};
