import selectize from "selectize";

function initSelectize(parent = null) {
  $('.selectize-multiple-dropdown').selectize({
    // to make this multiple u need to add 'multiple=""' to the select
    plugins: ['remove_button'],
  });

  $('select').not('select[class*="flatpickr"]').not('select[class*="not-alph"]').selectize({
    sortField: "text",
    dropdownParent: parent
  });

  $('select[class*="not-alph"]').selectize({
    //dropdowns which arnt sorted by alphebetical
  });

  // this fixes an issue where the placeholder isnt full width on load/change page
  $(".selectize-input input[placeholder]").attr("style", "width: 100%;");
}

$(document).on('turbolinks:load', () => {
  initSelectize();
});

window.pageSelectize = function(parent = null) {
  initSelectize(parent);
};
