$(document).on("turbolinks:load", function () {
  // These are just simple functions used to show/hide the loading spinner for slow loading screens.
  // startLoading() should be called on start of an ajax request (eg. onClick).
  // We then use an AJAX listener to check if the request is complete and hide the loading bar.
  // Note, this will only ever work for AJAX requests (remote: true), not normal page loads,
  // this means that search forms you want to use this with should also be remote: true and the submit (search) button needs to have the onClick.
  //
  // Controller setup:
  // ----------------
  //
  // def index
  //   # Load data only for AJAX requests and if not already loaded
  //   if request.xhr?
  //     # Some slow query here...
  //
  //     respond_to do |format|
  //       format.js do
  //         render 'index'
  //         response.headers['X-Loading-Complete'] = 'true'  # This tells the frontend that the data has been loaded
  //         return # Exit the action here for AJAX requests
  //       end
  //     end
  //   end
  //
  //   # Handle non-AJAX requests or when data is already loaded
  //   respond_to do |format|
  //     format.html { render 'index' }
  //     format.js { render 'index' } 
  //   end
  // end
  //
  // ----------------
  //
  // index.html.erb should have this partial and a link to make the ajax request:
  //
  // <%= render 'layouts/loading_spinner' %>
  // ....
  // <%= link_to "View All #{number_with_delimiter(@object_pre_search.size)}", index_path, 
  //     remote: true, 
  //     data: { type: 'script' }, 
  //     onClick: "startLoading();" %>
  
  //
  // ----------------
  
  window.startLoading = function () {
    $(".loading-overlay").show();
    
    let originalSend = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function(data) {
      this.addEventListener("load", function() {
        let complete = this.getResponseHeader("X-Loading-Complete");
        if (complete) {
          $(".loading-overlay").hide();
        }
      });
      originalSend.call(this, data);
    };
  };
  
});
