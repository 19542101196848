document.addEventListener("turbolinks:load", function() {
  clearSearchInputs();
})

function clearSearchInputs(params) {
  $("#clear-search, #clear-search-and-session").click(function() {
    $(':input','form').not(':button, :submit, :reset, :hidden').val('').removeAttr('checked').removeAttr('selected');
    //clear all selectize drop downs
    $("select").not('#random_questions').each(function() {
      if ($(this)[0].selectize != undefined) {
        $(this)[0].selectize.clear();
      }
    });
    if ($("select#per").length > 0) {
      if (window.location.href.includes('/content_view/')) {
        $("select#per")[0].selectize.setValue(50, true)
      } else {
        $("select#per")[0].selectize.setValue(10, true)
      }
    }
    if ($("#clear-search-and-session").length > 0) {
      //if the url contains a '?' then it already has params so use '&' instead
      if (window.location.href.indexOf("?") > -1) {
        window.location.href += '&remove_session=' + window.location.href;
      } else {
        window.location.href += '?remove_session=' + window.location.href;
      }
    } else {
      $("input[value='Search']").click();
    }
  });
}
